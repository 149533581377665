import React, { useState } from "react"
import firebase from "gatsby-plugin-firebase"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

function ResetPassword() {
  const [data, setData] = useState({
    email: "",
    error: null,
  })

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
    console.log(data.email)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setData({ ...data, error: null })
    try {
      console.log(data.email)
      await firebase.auth().sendPasswordResetEmail(data.email)
      alert("Password reset link sent!")
    } catch (err) {
      setData({ ...data, error: err.message })
    }
  }

  return (
    <main>
      <div
        className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          className="object-cover object-center w-full h-full"
          src="https://source.unsplash.com/2LALNs1C0No/1000x2100"
          alt=""
          width="760"
          height="1024"
        />
      </div>
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2 bg-white">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                <Link to="/">
                  <div className="">
                    <StaticImage
                      src="../images/siyo-logo.png"
                      width={80}
                      quality={100}
                      alt="Si-Yo Music Logo"
                    />
                    <span> </span>
                    <StaticImage
                      src="../images/ficec-logo-dark.png"
                      width={90}
                      quality={100}
                      alt="FICEC Logo"
                    />
                  </div>
                </Link>
                Reset Password
              </h1>
              {/* Form */}
              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="email"
                      name="email"
                      className="form-input w-full border p-1 rounded"
                      type="email"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap py-1 px-3 rounded"
                  >
                    Send Reset Link
                  </button>
                </div>
              </form>

              {data.error ? (
                <div className="mt-5">
                  <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                    <svg
                      className="inline w-3 h-3 flex-shrink-0 fill-current mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">{data.error}</span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ResetPassword
